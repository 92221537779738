import { SuspiciousRecordReasonDialogComponent } from '@/src/app/features/suspicious-records/components/suspicious-record-reason-dialog/suspicious-record-reason-dialog.component';
import { SuspiciousRecordComponent } from '@/src/app/features/suspicious-records/components/suspicious-record/suspicious-record.component';
import { SuspiciousRecordProblemType } from '@/src/app/features/suspicious-records/enums/problem-type.enum';
import { SuspiciousRecordReviewActionType } from '@/src/app/features/suspicious-records/enums/suspicious-records.enum';
import { SuspiciousRecordReviewResult } from '@/src/app/features/suspicious-records/models/suspicious-record-review-result.model';
import { SuspiciousRecordDialog } from '@/src/app/features/suspicious-records/models/suspicious-record.model';
import { SuspiciousRecordAndFrauds } from '@/src/app/features/suspicious-records/models/suspicious-records-and-frauds.model';
import { SuspiciousRecordsService } from '@/src/app/features/suspicious-records/suspicious-records.service';
import { execCallbackOnTreatmentStateError } from '@/src/app/features/suspicious-records/suspicious-records.util';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { SpinnerService } from '@/src/app/shared/components/spinner/service/spinner.service';
import { GlobalErrorHandlerService } from '@/src/app/shared/services/global-error-handler.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, signal, ViewChildren, WritableSignal } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { scrollToElement } from '@utils/scroll.utils';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';
import { PatientId } from '@src/app/features/patient/models/patient.model';

@Component({
  templateUrl: './suspicious-records-comparison-page.component.html',
  styleUrls: ['./suspicious-records-comparison-page.component.scss']
})
export class SuspiciousRecordsComparisonPageComponent extends BaseComponent implements OnInit, OnDestroy {
  suspiciousRecord: WritableSignal<SuspiciousRecordAndFrauds> = signal(null);
  searchFilters: WritableSignal<SuspiciousRecordAndFrauds> = signal(null);
  totalNumberOfRecords: WritableSignal<number> = signal(null);
  recordId: WritableSignal<TreatmentId> = signal(null);

  isAcceptDisabled: boolean;
  dupRejectedCount: number;
  @ViewChildren(SuspiciousRecordComponent) potentialDuplicates: SuspiciousRecordComponent[];

  private problemDialogReference?: MatDialogRef<SuspiciousRecordReasonDialogComponent>;

  constructor(
    private suspiciousRecordsService: SuspiciousRecordsService,
    private cd: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private globalErrorHandler: GlobalErrorHandlerService,
    private dialog: MatDialog,
    private router: Router,
    private wsHelperService: WsHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.navigateToSuspiciousRecordsToolWhenFiltersStateIsLost();
    this.resetAcceptBtnState();
    this.getSuspiciousRecordToReview();
  }

  private navigateToSuspiciousRecordsToolWhenFiltersStateIsLost(): void {
    if (this.suspiciousRecordsService.searchFilters() === null) {
      this.router.navigate(['/tools/suspicious-records']);
    }
  }

  private getSuspiciousRecordToReview(): void {
    this.subSink.sink = this.wsHelperService
      .callWithSpinner(this.suspiciousRecordsService.getSuspiciousRecord(this.suspiciousRecordsService.searchFilters()), {
        redirectOn404StatusCode: false
      })
      .subscribe({
        next: record => this.loadSuspiciousRecord(record)
      });
  }

  refreshRecord(): void {
    this.subSink.sink = this.suspiciousRecordsService
      .refreshSuspiciousRecord(this.recordId(), this.spinnerService, this.globalErrorHandler, this.cd)
      .subscribe({
        next: record => this.loadSuspiciousRecord(record, false),
        error: err => execCallbackOnTreatmentStateError(err, () => this.resetAcceptBtnState())
      });
  }

  private loadSuspiciousRecord(record: SuspiciousRecordAndFrauds, updateTotalNumberOfRecords = true): void {
    this.suspiciousRecord.set(record);
    this.recordId.set(record?.surgery?.id);

    if (!updateTotalNumberOfRecords) {
      return;
    }

    if (!!record) {
      this.totalNumberOfRecords.set(record?.numberOfRecordsForReview);
    } else {
      this.totalNumberOfRecords.set(0);
    }
  }

  ngOnDestroy() {
    this.problemDialogReference?.close();
    super.ngOnDestroy();
  }

  assessRecord(isAccepted: boolean): void {
    const dataForReviewSave: SuspiciousRecordDialog = {
      isAccepted: isAccepted,
      recordId: this.recordId(),
      patientId: this.suspiciousRecord().patient.id,
      dupTreatmentIds: this.getDupIds(),
      dupPatientList: this.getDupPatientIds(),
      notDupPatientList: this.getNotDupPatientIds()
    };
    if (isAccepted) {
      this.subSink.sink = this.suspiciousRecordsService
        .saveSuspiciousRecordReviewResult(
          dataForReviewSave,
          new SuspiciousRecordReviewResult(
            SuspiciousRecordReviewActionType.ACCEPT,
            dataForReviewSave.recordId,
            SuspiciousRecordProblemType.NOT_PROBLEMATIC,
            null,
            dataForReviewSave.dupTreatmentIds
          ),
          this.spinnerService,
          this.globalErrorHandler,
          this.cd
        )
        .subscribe({
          next: () => {
            this.resetAcceptBtnState();
            this.scrollToTop();
            this.getSuspiciousRecordToReview();
          },
          error: err => execCallbackOnTreatmentStateError(err, () => this.resetAcceptBtnState())
        });
    } else {
      this.problemDialogReference = this.dialog.open(SuspiciousRecordReasonDialogComponent, {
        data: dataForReviewSave,
        width: '100%'
      });
      this.subSink.sink = this.problemDialogReference
        ?.afterClosed()
        .pipe(filter((closedAfterAction: boolean) => !!closedAfterAction))
        .subscribe({
          next: () => {
            this.resetAcceptBtnState();
            this.scrollToTop();
            this.getSuspiciousRecordToReview();
          }
        });
    }
  }

  skipRecord(): void {
    this.subSink.sink = this.suspiciousRecordsService
      .skipRecord(this.recordId(), this.spinnerService, this.globalErrorHandler, this.cd)
      .subscribe({
        next: () => {
          this.resetAcceptBtnState();
          this.scrollToTop();
          this.getSuspiciousRecordToReview();
        },
        error: err => execCallbackOnTreatmentStateError(err, () => this.resetAcceptBtnState())
      });
  }

  scrollToTop(): void {
    scrollToElement('stx-scrollable-container');
  }

  toggleAcceptButton(isRejected: boolean): void {
    isRejected ? this.dupRejectedCount++ : this.dupRejectedCount--;

    this.isAcceptDisabled = this.dupRejectedCount !== 0;
  }

  private resetAcceptBtnState() {
    this.dupRejectedCount = 0;
    this.isAcceptDisabled = false;
  }

  getDupIds(): number[] {
    let dupIdList = [];
    if (this.potentialDuplicates) {
      this.potentialDuplicates.forEach(duplicate => {
        const maybeDupId = duplicate.getDupId();
        if (maybeDupId) {
          dupIdList.push(maybeDupId);
        }
      });
    }
    return dupIdList;
  }

  getDupPatientIds(): Set<PatientId> {
    let dupIdList = new Set<PatientId>();
    if (this.potentialDuplicates) {
      this.potentialDuplicates.forEach(duplicate => {
        const maybeDupPatientId = duplicate.getDupPatientId();
        if (maybeDupPatientId) {
          dupIdList.add(maybeDupPatientId);
        }
      });
    }
    return dupIdList;
  }

  getNotDupPatientIds(): Set<PatientId> {
    let notDupList = new Set<PatientId>();
    if (this.potentialDuplicates) {
      this.potentialDuplicates.forEach(duplicate => {
        const maybeNotDupPatientId = duplicate.getNotDupPatientId();
        if (maybeNotDupPatientId) {
          notDupList.add(maybeNotDupPatientId);
        }
      });
    }
    return notDupList;
  }

  isRecordLoaded(): boolean {
    return !!this.suspiciousRecord();
  }
}
