import { Component, inject, OnInit } from '@angular/core';
import { PasswordExpiryService } from '@src/app/features/authentication/services/password-expiry.service';
import { AuthService } from '@src/app/features/authentication/services/auth.service';
import { RoleEnum } from '@src/app/features/authentication/helpers/role.enum';

@Component({
  selector: 'stx-speech-qa-dashboard-page',
  templateUrl: './speech-qa-dashboard-page.component.html'
})
export class SpeechQaDashboardPageComponent implements OnInit {
  private passwordExpiryService: PasswordExpiryService = inject(PasswordExpiryService);
  private authService: AuthService = inject(AuthService);

  ngOnInit(): void {
    this.passwordExpiryService.showPasswordExpiryPopup();
  }

  get isViewDashboardForUser(): boolean {
    return this.authService.checkUserRole(RoleEnum.QA_REVIEWER_SPEECH);
  }
}
