import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { ExpandableRowTableComponent } from '@/src/app/shared/components/expandable-row-table/expandable-row-table.component';
import { ExpandableRowGroup } from '@/src/app/shared/components/expandable-row-table/expandable-row.model';
import { practitioners } from '@/src/app/utils/routing.utils';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ReviewService } from '../../../review/review.service';
import { OverrideQaStageMode, QaStageType } from '../../models/qa-results.enum';
import {
  OverrideQaStageParams,
  PractitionerDetailsForQa,
  QaReviewStage,
  QaReviewStageForDisplay,
  QaStageActions,
  QaStageModalConfig
} from '../../models/qa-results.model';
import { getPractitionerQaReviewForDisplay, qaTableColumns } from '../../utils/qa-results.utils';
import { OverrideQaStageModalComponent } from './override-qa-stage-modal/override-qa-stage-modal.component';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { AuthService } from '@src/app/features/authentication/services/auth.service';

@Component({
  selector: 'stx-qa-results',
  templateUrl: './qa-results-page.component.html',
  styleUrls: ['./qa-results-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QaResultsPageComponent extends BaseComponent implements OnInit {
  practitionerDetails: PractitionerDetailsForQa;
  qaStagesForDisplay: ExpandableRowGroup<QaReviewStageForDisplay>[];
  private activeStages: QaReviewStage[] = [];
  private clickedIconActions: QaStageActions;
  private practitionerId: number;

  readonly qaTableColumns = qaTableColumns;

  @ViewChild(ExpandableRowTableComponent, { static: false }) table: ExpandableRowTableComponent;

  get practitionerPage() {
    return `/${practitioners}/${this.practitionerId}`;
  }

  constructor(
    private reviewService: ReviewService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private readonly wsHelperService: WsHelperService,
    private readonly authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    this.subSink.sink = this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.practitionerId = params.id;
        this.getPractitionerQaDetails(this.practitionerId);
      }
    });

    this.clickedIconActions = {
      override: displayedStage => {
        this.override(displayedStage);
      },
      assignNewReviewStage: displayedStage => {
        this.openOverrideStageModal(OverrideQaStageMode.NEW_REVIEW_STAGE, params => {
          return this.reviewService.overrideQaReviewStage(displayedStage.id, params);
        });
      },
      editTrainingDate: displayedStage => {
        this.openOverrideStageModal(OverrideQaStageMode.ASSIGN_TRAINING_DATE, params => {
          return this.reviewService.updateQaReviewStage(displayedStage.id, { trainDate: params.trainDate });
        });
      }
    };
  }

  private getPractitionerQaDetails(practitionerId: number) {
    this.wsHelperService
      .callWithSpinner(this.reviewService.getPractitionerQaDetailsData(practitionerId), { redirectOn404StatusCode: true })
      .subscribe(practitionerQaReview => {
        if (practitionerQaReview) {
          this.practitionerDetails = practitionerQaReview.practitioner;

          if (practitionerQaReview.qaStage) {
            this.activeStages.push(...practitionerQaReview.qaStage.map(qs => qs.currentStage));
          }

          const hasUserPermissionToOverride =
            this.authService.checkUserPermission(PermissionEnum.OVERRIDE_QA_STAGE) && !this.practitionerDetails.readonly;

          this.qaStagesForDisplay = getPractitionerQaReviewForDisplay(
            practitionerQaReview.qaStage,
            this.clickedIconActions,
            hasUserPermissionToOverride
          );

          this.table?.renderRows(this.qaStagesForDisplay);
          this.cd.detectChanges();
        }
      });
  }

  private override(displayedStage: QaReviewStageForDisplay) {
    const stageData = this.activeStages.find(s => s.id === displayedStage.id);

    if (stageData) {
      this.wsHelperService
        .callWithSpinner(this.reviewService.overrideQaReviewStage(stageData.id, { nextStage: QaStageType.FURTHER_RESEARCH }))
        .subscribe(() => {
          this.getPractitionerQaDetails(this.practitionerId);
        });
    }
  }

  private openOverrideStageModal(mode: OverrideQaStageMode, saveAction: (params: OverrideQaStageParams) => {}) {
    this.dialog.open(OverrideQaStageModalComponent, {
      autoFocus: false,
      data: {
        mode,
        saveAction,
        refresh: () => {
          this.getPractitionerQaDetails(this.practitionerId);
        }
      } as QaStageModalConfig<OverrideQaStageParams>
    });
  }
}
