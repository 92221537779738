import { ChangeDetectionStrategy, Component, Input, ViewChildren } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Item } from '@shared/models/item.model';
import { AutocompleteMultiSelectComponent } from 'src/app/shared/components/autocomplete-multi-select/autocomplete-multi-select.component';
import { AutocompleteInput } from 'src/app/shared/models/form.model';
import { OrgTreeFilterModel } from '../../../models/org-tree-filter.model';
import { OrgTreeEnum } from '@src/app/features/search/enums/org-tree.enum';

@Component({
  selector: 'stx-org-tree-filter',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './org-tree-filter.component.html'
})
export class OrgTreeFilterComponent {
  @Input() set orgTreeFilters(orgTreeFilters: OrgTreeFilterModel) {
    if (orgTreeFilters) {
      this.orgTreeFiltersData = orgTreeFilters;

      this.setUpForms(orgTreeFilters);
    }
  }
  @Input() formGroupReference: UntypedFormGroup;

  @Input() set showPartial(partial: OrgTreeEnum) {
    this.partialType = partial;
  }
  @ViewChildren(AutocompleteMultiSelectComponent) orgFilters: AutocompleteMultiSelectComponent[];
  orgTreeSetup = new Map<string, AutocompleteInput>();
  orgTreeFiltersData: OrgTreeFilterModel;

  partialType: OrgTreeEnum;

  onRemoveChip($event: Item, control: AbstractControl): void {
    const newValues = (control.value as Array<Item>).filter((value: Item) => value.id !== $event.id);

    control.setValue(newValues);
  }
  isShown(controlKey: string): boolean {
    return this.orgTreeSetup.get(controlKey)?.data?.length > 0;
  }

  private setUpForms(filtersData: OrgTreeFilterModel): void {
    if (!this.partialType) {
      this.orgTreeSetup
        .set('partners', {
          name: 'partner.partner',
          formControlName: 'partners',
          data: filtersData.partners
        })
        .set('treatmentCenters', {
          name: 'treatment_center.treatment_center',
          formControlName: 'treatmentCenters',
          data: filtersData.treatmentCenters
        });
    }

    if (this.partialType === OrgTreeEnum.PARTNERS) {
      this.orgTreeSetup.set('partners', {
        name: 'partner.partner',
        formControlName: 'partners',
        data: filtersData.partners
      });
    }
  }
}
