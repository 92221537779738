import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { PermissionEnum } from 'src/app/core/permissions/permission.enum';
import { AuthService } from 'src/app/features/authentication/services/auth.service';
import { SearchService } from 'src/app/features/search/search.service';
import { SearchBaseComponent } from '../../../search/components/search-base/search-base.component';
import { getIdsFromItemFormControl } from '../../../search/search.utils';
import { SearchFilterService } from '../../../search/services/search-filter.service';
import { PartnerSearchModel } from '../../models/partner-search.model';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Component({
  selector: 'stx-partner-search',
  templateUrl: './partner-search.component.html'
})
export class PartnerSearchComponent extends SearchBaseComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private wsHelperService: WsHelperService,
    searchFilterService: SearchFilterService
  ) {
    super(searchFilterService);
  }

  ngOnInit() {
    this.initializePartnerSearchForm();
    this.loadFilters();
  }

  protected getSearchFilters(): PartnerSearchModel {
    return Object.assign({}, this.searchFormGroup.value, this.getOrgTreeRequestParams(), this.getGeoographyTreeRequestParams());
  }

  loadFilters(): void {
    if (this.authService.checkUserPermission(PermissionEnum.GET_ORG_TREE_FILTERS)) {
      this.wsHelperService.callWithSpinner(this.searchService.getOrgTreeFilters()).subscribe(filters => {
        this.orgTreeFiltersData = filters;
        this.cd.detectChanges();
      });
    }
  }

  private getOrgTreeRequestParams(): Record<string, string[]> {
    const keysToMap = ['partners', 'treatmentCenters'];

    return getIdsFromItemFormControl(this.searchFormGroup, keysToMap);
  }

  private getGeoographyTreeRequestParams(): Record<string, string[]> {
    const keysToMap = ['crms', 'crmRegions', 'countries', 'zoneCountries', 'provinces', 'zoneProvinces'];

    return getIdsFromItemFormControl(this.searchFormGroup, keysToMap);
  }

  private initializePartnerSearchForm(): void {
    this.searchFormGroup = this.formBuilder.group({
      active: [false],
      inactive: [false],
      outreachCenter: [false],
      webIgnore: [false],
      allowSurgery: [false],
      allowVelo: [false],
      allowOrthognathic: [false],
      allowBorescope: [false],
      allowOrtho: [false],
      allowSpeech: [false],
      allowNutrition: [false],
      allowDental: [false],
      allowEnt: [false],
      allowPsychosocial: [false],
      costSharePrimaryNotCovered: [false],
      partners: [[]],
      treatmentCenters: [[]],
      crms: [[]],
      crmRegions: [[]],
      countries: [[]],
      zoneCountries: [[]],
      provinces: [[]],
      zoneProvinces: [[]]
    });
  }
}
