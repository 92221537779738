import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@shared/models/user/user.model';
import { environment } from '@src/environments/environment';
import { PasswordExpiryModalComponent } from '@src/app/features/authentication/components/password-expiry-modal/password-expiry-modal.component';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';
import { InstanceArea } from '@shared/models/stxconfiguration.model';
import { NonGlobalPasswordExpiryModalComponent } from '@src/app/features/authentication/components/china-password-expiry-modal/non-global-password-expiry-modal.component';
import { ConfigurationService } from '@shared/services/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class PasswordExpiryService {
  private showExpiredCredentialsMessage = false;
  constructor(private currentUserService: CurrentUserService, private dialog: MatDialog, private configService: ConfigurationService) {}
  shouldShowPasswordExpiryPopup(): boolean {
    const user: User = this.currentUserService.getCurrentUser();
    return user ? !user.passwordExpiryPopupShown && user.passwordExpiryRemainingDays <= environment.passwordExpiryPopupThreshold : false;
  }

  public async isGlobalInstance(): Promise<boolean> {
    const currentArea = await this.configService.getCurrentInstanceArea();
    return currentArea === InstanceArea.GLOBAL;
  }

  setUserPasswordExpiryPopupShown(value: boolean): void {
    let user: User = this.currentUserService.getCurrentUser();
    if (user) {
      user.passwordExpiryPopupShown = value;
      this.currentUserService.setCurrentUser(user);
    }
  }

  getShowExpiredCredentialsMessage(): boolean {
    return this.showExpiredCredentialsMessage;
  }

  setShowExpiredPasswordMessage(value: boolean): void {
    this.showExpiredCredentialsMessage = value;
  }

  showPasswordExpiryPopup() {
    if (this.shouldShowPasswordExpiryPopup()) {
      this.isGlobalInstance().then(isGlobal => {
        if (isGlobal) {
          this.dialog.open(PasswordExpiryModalComponent);
        } else {
          this.dialog.open(NonGlobalPasswordExpiryModalComponent);
        }
        this.setUserPasswordExpiryPopupShown(true);
      });
    }
  }
}
