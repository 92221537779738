<stx-generic-page>
  @if(isViewDashboardForUser) {
  <stx-password-expiry></stx-password-expiry>
  }

  <div class="flex-column-container">
    <div class="data-section">
      <h3>{{ 'speech_qa_dashboard.first_header' | translate }}</h3>
    </div>
  </div>

  <ng-container *ngTemplateOutlet="reviewElementsDescription"></ng-container>
</stx-generic-page>

<ng-template #reviewElementsDescription>
  <div class="flex-column-container">
    <div class="layout-columns-1-1-1">
      <div>
        <div class="data-tile-container">
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-header">{{ 'speech_qa_dashboard.skipping_records.header' | translate }}</div>
              <div class="data-tile-content">
                {{ 'speech_qa_dashboard.skipping_records.content' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="data-tile-container">
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-header">{{ 'speech_qa_dashboard.instrumental_assessment.header' | translate }}</div>
              <div class="data-tile-content">
                {{ 'speech_qa_dashboard.instrumental_assessment.content' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="data-tile-container">
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-header">{{ 'speech_qa_dashboard.number_of_disagreements.header' | translate }}</div>
              <div class="data-tile-content">
                {{ 'speech_qa_dashboard.number_of_disagreements.content' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layout-columns-1-1-1">
      <div>
        <div class="data-tile-container">
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-header">{{ 'speech_qa_dashboard.technical_comments.header' | translate }}</div>
              <div class="data-tile-content">
                {{ 'speech_qa_dashboard.technical_comments.content' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="data-tile-container">
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-header">{{ 'speech_qa_dashboard.unable_to_assess.header' | translate }}</div>
              <div class="data-tile-content">
                {{ 'speech_qa_dashboard.unable_to_assess.content' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="data-tile-container">
          <div class="data-tile-row">
            <div class="data-tile">
              <div class="data-tile-header">{{ 'speech_qa_dashboard.reviewers_grade.header' | translate }}</div>
              <div class="data-tile-content">
                {{ 'speech_qa_dashboard.reviewers_grade.content' | translate }}
                <div>
                  <ul class="data-spaced-column">
                    <li>
                      <strong>{{ ('speech_qa_pass.header' | translate) + ':' }}</strong>
                      <span>
                        {{ 'speech_qa_dashboard.reviewers_grade.content_pass' | translate }}
                      </span>
                    </li>
                    <li>
                      <strong>{{ ('speech_qa_monitor.header' | translate) + ':' }}</strong>
                      <span>
                        {{ 'speech_qa_dashboard.reviewers_grade.content_monitor' | translate }}
                      </span>
                    </li>
                    <li>
                      <strong>{{ ('speech_qa_fail.header' | translate) + ':' }}</strong>
                      <span>
                        {{ 'speech_qa_dashboard.reviewers_grade.content_fail' | translate }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
