import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionEnum } from '@core/permissions/permission.enum';
import {
  errorResourceNotFound,
  imageViewerRoute,
  loginRoute,
  maintenancePageRoute,
  nutritionRoute,
  nutritionPilotRoute,
  offlineAppRoute,
  orthognathicRoute,
  orthoQaRoute,
  orthoRoute,
  partnersRoute,
  patientRoute,
  patientsRoute,
  practitioners,
  reportsMainRoute,
  resetPasswordRoute,
  resourcesRoute,
  reviewRoute,
  selectProfileRoute,
  settingsRoute,
  speechRoute,
  surgicalRoute,
  toolsRoute,
  treatmentCentersRoute,
  usersRoute,
  speechQaRoute
} from '@utils/routing.utils';
import { LoginComponent } from './features/authentication/components/login/login.component';
import { ResetPasswordComponent } from './features/authentication/components/reset-password/reset-password.component';
import { AuthGuard } from './features/authentication/helpers/auth.guard';
import { PermissionGuard } from './features/authentication/helpers/permission.guard';
import { EditSettingsComponent, EditSettingsComponentModule } from './features/edit-settings/edit-settings.component';
import { ResourceNotFoundPageComponent } from './features/resource-not-found-page/resource-not-found-page.component';
import { TreatmentCenterDetailsPageComponent } from './features/treatment-center/components/treatment-center-details-page/treatment-center-details-page.component';
import { SelectProfileComponent } from './features/user/components/select-profile/select-profile.component';
import { NotFoundErrorType } from '@src/app/features/resource-not-found-page/enums/not-found-error.type';
import { RegionalRedirectGuard } from '@src/app/features/regional-redirect/regional-redirect.guard';
import { TokenComponent } from '@src/app/features/authentication/components/token/token.component';
import { MaintenancePageComponent } from '@core/maintenance/components/maintenance-page.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/dashboard/dashboard-routing.module').then(m => m.DashboardRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: settingsRoute,
    component: EditSettingsComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: { newStyles: true, permission: PermissionEnum.EDIT_USER_SETTINGS }
  },
  { path: selectProfileRoute, component: SelectProfileComponent, canActivate: [AuthGuard] },
  {
    path: patientsRoute,
    loadChildren: () => import('./features/patient-search/patient-search-routing.module').then(m => m.PatientSearchRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: patientRoute,
    loadChildren: () => import('./features/patient/patient-routing.module').then(m => m.PatientRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: surgicalRoute,
    loadChildren: () => import('./features/surgical/surgical-routing.module').then(m => m.SurgicalRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: orthoQaRoute,
    loadChildren: () => import('./features/ortho-qa/ortho-qa-routing.module').then(m => m.OrthoQaRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: speechQaRoute,
    loadChildren: () => import('./features/speech-qa/speech-qa-routing.module').then(m => m.SpeechQaRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: speechRoute,
    loadChildren: () => import('./features/speech/speech-routing.module').then(m => m.SpeechRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: orthognathicRoute,
    loadChildren: () => import('./features/orthognathic/orthognathic-routing.module').then(m => m.OrthognathicRoutingModule)
  },
  {
    path: orthoRoute,
    loadChildren: () => import('./features/ortho/ortho-routing.module').then(m => m.OrthoRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: nutritionRoute,
    loadChildren: () => import('./features/nutrition-management/nutrition-routing.module').then(m => m.NutritionRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: nutritionPilotRoute,
    loadChildren: () => import('./features/nutrition/nutrition-historical-routing.module').then(m => m.NutritionHistoricalRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: partnersRoute,
    loadChildren: () => import('./features/partner/partner.module').then(m => m.PartnerModule),
    canActivate: [AuthGuard, PermissionGuard],
    canLoad: [AuthGuard],
    data: { permission: PermissionEnum.GET_PARTNERS_RECORDS }
  },
  {
    path: practitioners,
    loadChildren: () => import('./features/practitioner/practitioner.module').then(m => m.PractitionerModule),
    canActivate: [AuthGuard, PermissionGuard],
    canLoad: [AuthGuard],
    data: { permission: PermissionEnum.GET_PRACTITIONERS }
  },
  {
    path: usersRoute,
    loadChildren: () => import('./features/user/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard, PermissionGuard],
    canLoad: [AuthGuard],
    data: { permission: PermissionEnum.GET_USERS }
  },
  {
    path: offlineAppRoute,
    loadChildren: () => import('./features/offline-app/offline-app-routing.module').then(m => m.OfflineAppRoutingModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: toolsRoute,
    loadChildren: () => import('./features/tools/tools-routing.module').then(m => m.ToolsRoutingModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: resourcesRoute,
    loadChildren: () =>
      import('./features/educational-resources/educational-resources-routing.module').then(m => m.EducationalResourcesRoutingModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: `${treatmentCentersRoute}/:id`,
    component: TreatmentCenterDetailsPageComponent,
    canActivate: [AuthGuard, PermissionGuard],
    canLoad: [AuthGuard],
    data: { permission: PermissionEnum.GET_TREATMENT_CENTER_DETAILS, newStyles: true }
  },
  {
    path: reviewRoute,
    loadChildren: () => import('./features/review/review-routing.module').then(m => m.ReviewsRoutingModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: reportsMainRoute,
    loadChildren: () => import('./features/reports/reports-routing.module').then(m => m.ReportsRoutingModule),
    canLoad: [AuthGuard]
  },
  { path: loginRoute, component: LoginComponent, canActivate: [RegionalRedirectGuard] },
  { path: resetPasswordRoute, component: ResetPasswordComponent },
  { path: 'token/:profileId/:token', component: TokenComponent },
  {
    path: imageViewerRoute,
    loadChildren: () => import('./features/image-viewer/image-viewer.module').then(m => m.ImageViewerModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: maintenancePageRoute,
    component: MaintenancePageComponent
  },
  {
    path: errorResourceNotFound,
    component: ResourceNotFoundPageComponent,
    data: { errorType: NotFoundErrorType.RESOURCE_NOT_FOUND }
  },
  {
    path: '**',
    component: ResourceNotFoundPageComponent,
    data: { errorType: NotFoundErrorType.URL_NOT_FOUND }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top'
    }),
    EditSettingsComponentModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
