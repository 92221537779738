export enum PermissionEnum {
  GET_USERS = 'GET_USERS',
  GET_USER = 'GET_USER',
  ENABLE_USER_LINK = 'ENABLE_USER_LINK',
  GET_MEDIA = 'GET_MEDIA',
  STORE_MEDIA = 'STORE_MEDIA',
  GET_PARTNERS_RECORDS = 'GET_PARTNERS_RECORDS',
  GET_PARTNER_DETAILS = 'GET_PARTNER_DETAILS',
  GET_PARTNER_TREATMENT_CENTERS = 'GET_PARTNER_TREATMENT_CENTERS',
  GET_PARTNER_USERS = 'GET_PARTNER_USERS',
  GET_TREATMENT_CENTER_DETAILS = 'GET_TREATMENT_CENTER_DETAILS',
  GET_TREATMENT_CENTER_PRACTITIONERS = 'GET_TREATMENT_CENTER_PRACTITIONERS',
  GET_TREATMENT_CENTER_PARTNER_DETAILS = 'GET_TREATMENT_CENTER_PARTNER_DETAILS',
  GET_TREATMENT_CENTER_USERS = 'GET_TREATMENT_CENTER_USERS',
  GET_PRACTITIONER_DETAILS = 'GET_PRACTITIONER_DETAILS',
  GET_PRACTITIONER_TREATMENT_CENTERS = 'GET_PRACTITIONER_TREATMENT_CENTERS',
  ADD_PRACTITIONER_NOTE = 'ADD_PRACTITIONER_NOTE',
  READ_PRACTITIONER_NOTE = 'READ_PRACTITIONER_NOTE',
  GET_PRACTITIONERS = 'GET_PRACTITIONERS',
  GET_TEMP_LANG_CHANGE = 'GET_TEMP_LANG_CHANGE',
  EDIT_USER = 'EDIT_USER',
  NEW_USER = 'NEW_USER',
  CALCULATE_HEALTHY_WEIGHT = 'CALCULATE_HEALTHY_WEIGHT',
  GET_WHO_REFERENCE_DATA = 'GET_WHO_REFERENCE_DATA',
  SAVE_PATIENT = 'SAVE_PATIENT',
  GET_PATIENTS = 'GET_PATIENTS',
  GET_PATIENT = 'GET_PATIENT',
  CLONE_PATIENT = 'CLONE_PATIENT',
  VIEW_DASHBOARD_REDIRECT_USERS = 'VIEW_DASHBOARD_REDIRECT_USERS',
  VIEW_DASHBOARD_REDIRECT_PATIENTS = 'VIEW_DASHBOARD_REDIRECT_PATIENTS',
  VIEW_DASHBOARD_MAIN = 'VIEW_DASHBOARD_MAIN',
  VIEW_DASHBOARD_MAIN_CURRENT_MONTH = 'VIEW_DASHBOARD_MAIN_CURRENT_MONTH',
  VIEW_DASHBOARD_REVIEW_SURGICAL = 'VIEW_DASHBOARD_REVIEW_SURGICAL',
  VIEW_DASHBOARD_REVIEW_ORTHO = 'VIEW_DASHBOARD_REVIEW_ORTHO',
  VIEW_DASHBOARD_REVIEW_SPEECH = 'VIEW_DASHBOARD_REVIEW_SPEECH',
  GET_SURGERY_FOR_REVIEW = 'GET_SURGERY_FOR_REVIEW',

  GET_ORG_TREE_FILTERS = 'GET_ORG_TREE_FILTERS',
  AFFILIATED_PARTNERS_FILTER = 'AFFILIATED_PARTNERS_FILTER',

  VIEW_TOOLS = 'VIEW_TOOLS',
  VIEW_TOOLS_PATIENT_MATCHING = 'VIEW_TOOLS_PATIENT_MATCHING',
  VIEW_TOOLS_GENERAL = 'VIEW_TOOLS_GENERAL',
  VIEW_TOOLS_QA_ANALYTICS = 'VIEW_TOOLS_QA_ANALYTICS',

  VIEW_STATUSBAR_TREATMENT_SOURCE = 'VIEW_STATUSBAR_TREATMENT_SOURCE',

  GET_RESOURCES = 'GET_RESOURCES',
  GET_MEDICAL_LIBRARY_RECORDS_STATUS = 'GET_MEDICAL_LIBRARY_RECORDS_STATUS',
  GET_MEDICAL_LIBRARY_FILTERS = 'GET_MEDICAL_LIBRARY_FILTERS',
  GET_PAPER_RECORDS = 'GET_PAPER_RECORDS',
  SAVE_MEDICAL_LIBRARY_RECORDS = 'SAVE_MEDICAL_LIBRARY_RECORDS',

  GET_FOLLOW_UP = 'GET_FOLLOW_UP',
  SAVE_FOLLOW_UP = 'SAVE_FOLLOW_UP',

  GET_SURGERY = 'GET_SURGERY',
  SAVE_SURGERY = 'SAVE_SURGERY',

  GET_SPEECH_ASSESSMENT = 'GET_SPEECH_ASSESSMENT',
  SAVE_SPEECH_ASSESSMENT = 'SAVE_SPEECH_ASSESSMENT',

  SAVE_BORESCOPE = 'SAVE_BORESCOPE',
  GET_BORESCOPE = 'GET_BORESCOPE',

  SAVE_ORTHOGNATHIC = 'SAVE_ORTHOGNATHIC',
  GET_ORTHOGNATHIC = 'GET_ORTHOGNATHIC',

  GET_SPEECH_TREATMENT = 'GET_SPEECH_TREATMENT',
  SAVE_SPEECH_TREATMENT = 'SAVE_SPEECH_TREATMENT',
  GET_NUTRITION_MANAGEMENT = 'GET_NUTRITION_MANAGEMENT',
  SAVE_NUTRITION_MANAGEMENT = 'SAVE_NUTRITION_MANAGEMENT',
  GET_NUTRITION_ASSESSMENT = 'GET_NUTRITION_ASSESSMENT',
  SAVE_NUTRITION_ASSESSMENT = 'SAVE_NUTRITION_ASSESSMENT',
  GET_NUTRITION_FOLLOW_UP_HISTORICAL = 'GET_NUTRITION_FOLLOW_UP_HISTORICAL',
  SAVE_NUTRITION_FOLLOW_UP = 'SAVE_NUTRITION_ASSESSMENT',

  UNLOCK_TREATMENT_ALWAYS = 'UNLOCK_TREATMENT_ALWAYS',
  UNLOCK_PATIENT = 'UNLOCK_PATIENT',

  GET_PROVINCE_ZONES = 'GET_PROVINCE_ZONES',
  SAVE_PROVINCE_ZONE = 'SAVE_PROVINCE_ZONE',

  GET_COUNTRY_ZONES = 'GET_COUNTRY_ZONES',
  SAVE_COUNTRY_ZONE = 'SAVE_COUNTRY_ZONE',

  DELETE_TREATMENT_ALWAYS = 'DELETE_TREATMENT_ALWAYS',
  UPLOAD_OFFLINE_APP_FILE = 'UPLOAD_OFFLINE_APP_FILE',

  MATCH_PATIENTS = 'MATCH_PATIENTS',
  DELETE_PATIENT = 'DELETE_PATIENT',
  UNLINK_PATIENT = 'UNLINK_PATIENT',

  GET_SPEECH_INSTRUCTIONS = 'GET_SPEECH_INSTRUCTIONS',
  GET_PATIENT_TREATMENT_HISTORY = 'GET_PATIENT_TREATMENT_HISTORY',

  GET_PRACTITIONER_QA_REVIEW_DETAILS = 'GET_PRACTITIONER_QA_REVIEW_DETAILS',
  OVERRIDE_QA_STAGE = 'OVERRIDE_QA_STAGE',
  UPDATE_QA_STAGE = 'UPDATE_QA_STAGE',
  ADD_NEW_TREATMENT = 'ADD_NEW_TREATMENT',

  REVIEW_SUSPICIOUS_RECORDS = 'REVIEW_SUSPICIOUS_RECORDS',
  RUN_EXCEEDING_QUOTA = 'RUN_EXCEEDING_QUOTA',

  REFRESH_COUNTIES_XML = 'REFRESH_COUNTIES_XML',
  REFRESH_PARTNERS_XML = 'REFRESH_PARTNERS_XML',
  REFRESH_COUNTRIES_JSON = 'REFRESH_COUNTRIES_JSON',
  REFRESH_PARTNERS_JSON = 'REFRESH_PARTNERS_JSON',

  // -------------   #REPORTS#   -------------
  // FILTERS
  VIEW_REPORT_QA_FILTER = 'VIEW_REPORT_QA_FILTER',
  VIEW_REPORT_DE_IDENTIFICATION_FILTER = 'VIEW_REPORT_DE_IDENTIFICATION_FILTER',
  VIEW_REPORT_REJECTED_RECORDS_FILTER = 'VIEW_REPORT_REJECTED_RECORDS_FILTER',

  // DOWNLOADS
  GET_ADMIN_REPORT_SURGICAL_TREATMENTS = 'GET_ADMIN_REPORT_SURGICAL_TREATMENTS',
  GET_ADMIN_REPORT_ORTHOGNATHIC_TREATMENTS = 'GET_ADMIN_REPORT_ORTHOGNATHIC_TREATMENTS',
  GET_ADMIN_REPORT_NUTRITION_TREATMENTS = 'GET_ADMIN_REPORT_NUTRITION_TREATMENTS',
  GET_ADMIN_REPORT_SPEECH_TREATMENTS = 'GET_ADMIN_REPORT_SPEECH_TREATMENTS',
  GET_ADMIN_REPORT_ORTHODONTIC_TREATMENTS = 'GET_ADMIN_REPORT_ORTHODONTIC_TREATMENTS',

  // VIEWS
  GET_REPORTS = 'GET_REPORTS',
  GET_REPORT_SURGICAL_TREATMENTS = 'GET_REPORT_SURGICAL_TREATMENTS',
  GET_REPORT_ORTHOGNATHIC_TREATMENTS = 'GET_REPORT_ORTHOGNATHIC_TREATMENTS',
  GET_REPORT_SURGERY_STATS = 'GET_REPORT_SURGERY_STATS',
  GET_REPORT_FOLLOWUP_TREATMENTS = 'GET_REPORT_FOLLOWUP_TREATMENTS',
  GET_REPORT_YEAR_TO_DATE = 'GET_REPORT_YEAR_TO_DATE',
  GET_REPORT_YEAR_TO_DATE_BY_REGION = 'GET_REPORT_YEAR_TO_DATE_BY_REGION',
  GET_REPORT_SURGICAL_TREATMENTS_COMPACT = 'GET_REPORT_SURGICAL_TREATMENTS_COMPACT',
  GET_REPORT_SPEECH_STATS = 'GET_REPORT_SPEECH_STATS',
  GET_REPORT_PHOTO = 'GET_REPORT_PHOTO',
  GET_REPORT_ORTHODONTIC_TREATMENTS = 'GET_REPORT_ORTHODONTIC_TREATMENTS',
  GET_REPORT_ORTHODONTIC_TREATMENTS_COMPACT = 'GET_REPORT_ORTHODONTIC_TREATMENTS_COMPACT',
  GET_REPORT_SPEECH_TREATMENTS = 'GET_REPORT_SPEECH_TREATMENTS',
  GET_REPORT_QA_REVIEWER_RELIABILITY = 'GET_REPORT_QA_REVIEWER_RELIABILITY',
  GET_REPORT_QA_ORTHO_CASES = 'GET_REPORT_QA_ORTHO_CASES',
  GET_REPORT_MONTH_END = 'GET_REPORT_MONTH_END',
  GET_REPORT_PATIENT_DIAGNOSIS_TREATMENTS = 'GET_REPORT_PATIENT_DIAGNOSIS_TREATMENTS',
  GET_REPORT_ORTHODONTIC_STATS = 'GET_REPORT_ORTHODONTIC_STATS',
  GET_REPORT_PARTNER_SURGERY_COUNTS = 'GET_REPORT_PARTNER_SURGERY_COUNTS',
  GET_REPORT_PARTNER_SURGERY_COUNTS_FOUNDATION_CONNECT = 'GET_REPORT_PARTNER_SURGERY_COUNTS_FOUNDATION_CONNECT',
  GET_REPORT_NUTRITION_TREATMENTS = 'GET_REPORT_NUTRITION_TREATMENTS',
  GET_REPORT_QA_EVALUATOR_REVIEWS_SURGERY = 'GET_REPORT_QA_EVALUATOR_REVIEWS_SURGERY',
  GET_REPORT_QA_EVALUATOR_REVIEWS_SPEECH = 'GET_REPORT_QA_EVALUATOR_REVIEWS_SPEECH',
  GET_REPORT_QA_EVALUATOR_REVIEWS_ORTHO = 'GET_REPORT_QA_EVALUATOR_REVIEWS_ORTHO',
  GET_QA_SPEECH_REVIEWERS_EVALUATION_OLD_DATA = 'GET_QA_SPEECH_REVIEWERS_EVALUATION_OLD_DATA',
  GROUP_REPORT_RESULT_BY_PARTNER = 'GROUP_REPORT_RESULT_BY_PARTNER',

  ATTACH_RELEASE_FORM = 'ATTACH_RELEASE_FORM',
  GET_NUTRITION_SCREENING_HISTORICAL = 'GET_NUTRITION_SCREENING_HISTORICAL',
  DELETE_NUTRITION_SCREENING_HISTORICAL = 'DELETE_NUTRITION_SCREENING_HISTORICAL',
  GET_NUTRITION_FOLLOW_UP = 'GET_NUTRITION_FOLLOW_UP',
  DELETE_NUTRITION_FOLLOW_UP_HISTORICAL = 'DELETE_NUTRITION_FOLLOW_UP_HISTORICAL',
  AUTO_REDIRECT_TO_QA_EVALUATOR_REVIEWS_SURGICAL = 'AUTO_REDIRECT_TO_QA_EVALUATOR_REVIEWS_SURGICAL',
  AUTO_REDIRECT_TO_QA_EVALUATOR_REVIEWS_SPEECH = 'AUTO_REDIRECT_TO_QA_EVALUATOR_REVIEWS_SPEECH',
  AUTO_REDIRECT_TO_QA_EVALUATOR_REVIEWS_ORTHO = 'AUTO_REDIRECT_TO_QA_EVALUATOR_REVIEWS_ORTHO',
  GET_REPORT_NUTRITION_STATS = 'GET_REPORT_NUTRITION_STATS',

  SAVE_MEDICAL_MEETING = 'SAVE_MEDICAL_MEETING',
  GET_PARTNER_SURVEYS = 'GET_PARTNER_SURVEYS',

  GET_ORTHO_PSIO_ASSESSMENT = 'GET_ORTHO_PSIO_ASSESSMENT',
  SAVE_ORTHO_PSIO_ASSESSMENT = 'SAVE_ORTHO_PSIO_ASSESSMENT',

  GET_ORTHO_PSIO_TREATMENT = 'GET_ORTHO_PSIO_TREATMENT',
  SAVE_ORTHO_PSIO_TREATMENT = 'SAVE_ORTHO_PSIO_TREATMENT',
  GET_ORTHO_MD_ASSESSMENT = 'GET_ORTHO_MD_ASSESSMENT',
  SAVE_ORTHO_MD_ASSESSMENT = 'SAVE_ORTHO_MD_ASSESSMENT',
  GET_ORTHO_PD_ASSESSMENT = 'GET_ORTHO_PD_ASSESSMENT',
  SAVE_ORTHO_PD_ASSESSMENT = 'SAVE_ORTHO_PD_ASSESSMENT',
  GET_ORTHO_MD_TREATMENT = 'GET_ORTHO_MD_TREATMENT',
  SAVE_ORTHO_MD_TREATMENT = 'SAVE_ORTHO_MD_TREATMENT',
  GET_ORTHO_PD_TREATMENT = 'GET_ORTHO_PD_TREATMENT',
  SAVE_ORTHO_PD_TREATMENT = 'SAVE_ORTHO_PD_TREATMENT',
  SAVE_ORTHO_MD_MID_ASSESSMENT = 'SAVE_ORTHO_MD_MID_ASSESSMENT',
  SAVE_ORTHO_PD_MID_ASSESSMENT = 'SAVE_ORTHO_PD_MID_ASSESSMENT',
  GET_ORTHO_MD_MID_ASSESSMENT = 'GET_ORTHO_MD_MID_ASSESSMENT',
  GET_ORTHO_PD_MID_ASSESSMENT = 'GET_ORTHO_PD_MID_ASSESSMENT',
  SAVE_ENT_ASSESSMENT = 'SAVE_ENT_ASSESSMENT',
  SAVE_ENT_SURGERY = 'SAVE_ENT_SURGERY',
  SAVE_ENT_HEARING_ASSESSMENT = 'SAVE_ENT_HEARING_ASSESSMENT',
  SAVE_ORAL_HEALTH_NO_TEETH = 'SAVE_ORAL_HEALTH_NO_TEETH',
  SAVE_ORAL_HEALTH = 'SAVE_ORAL_HEALTH',
  SAVE_ORAL_HEALTH_DENTAL_TREATMENT = 'SAVE_ORAL_HEALTH_DENTAL_TREATMENT',
  OVERRIDE_SAQ_ON_SAVE_SURGERY = 'OVERRIDE_SAQ_ON_SAVE_SURGERY',
  REFRESH_STATISTICS = 'REFRESH_STATISTICS',
  REFRESH_SUSPICIOUS_RECORDS = 'REFRESH_SUSPICIOUS_RECORDS',
  EDIT_USER_SETTINGS = 'EDIT_USER_SETTINGS',
  VIEW_QA_RESULTS = 'VIEW_QA_RESULTS'
}
