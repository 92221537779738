import { Component } from '@angular/core';
import { environment } from '@src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';
import { PasswordExpiryService } from '@src/app/features/authentication/services/password-expiry.service';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'stx-password-expiry',
  templateUrl: './password-expiry.component.html',
  styleUrls: ['password-expiry.component.scss']
})
export class PasswordExpiryComponent {
  shouldShowChangePasswordButton$: Observable<boolean>;

  constructor(
    private currentUserService: CurrentUserService,
    private translateService: TranslateService,
    private passwordExpiryService: PasswordExpiryService
  ) {
    this.shouldShowChangePasswordButton$ = this.checkPasswordExpiryButton();
  }

  shouldShowPasswordExpiryMessage(): boolean {
    return this.getPasswordExpiryRemainingDays() <= environment.passwordExpiryNotificationThreshold;
  }

  private checkPasswordExpiryButton(): Observable<boolean> {
    return from(this.passwordExpiryService.isGlobalInstance()).pipe(
      map(isGlobalEnvironment => this.passwordExpired() && isGlobalEnvironment)
    );
  }

  private passwordExpired(): boolean {
    return this.getPasswordExpiryRemainingDays() <= environment.passwordExpiryNotificationThreshold;
  }

  getPasswordExpiryRemainingDays(): number {
    const remainingDays = this.currentUserService?.getCurrentUser()?.passwordExpiryRemainingDays;
    return remainingDays === undefined || remainingDays < 0 ? 0 : remainingDays;
  }

  getPasswordExpiryMessage(): string {
    let remainingDays = this.getPasswordExpiryRemainingDays();
    let message: string;

    this.translateService
      .get(
        remainingDays > environment.passwordExpiryPopupThreshold
          ? 'dashboard.password_expiry_message_consider_update'
          : 'dashboard.password_expiry_message'
      )
      .subscribe((translation: string) => {
        message = translation;
      });

    if (remainingDays > environment.passwordExpiryPopupThreshold) {
      return message;
    } else {
      return `${message} ${remainingDays}`;
    }
  }
}
