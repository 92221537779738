import { DictionaryTranslation, PatientId } from '@/src/app/features/patient/models/patient.model';
import { PatientService } from '@/src/app/features/patient/patient.service';
import { SurgicalIntervention } from '@/src/app/features/surgical/models/surgical-intervention.model';
import { SurgicalService } from '@/src/app/features/surgical/surgical.service';
import { TreatmentCenterService } from '@/src/app/features/treatment-center/treatment-center.service';
import { BaseTreatmentFormComponent } from '@/src/app/shared/components/base-treatment-form/base-treatment-form.component';
import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '@shared/components/spinner/service/spinner.service';
import { TreatmentType } from '@shared/components/treatment/treatment.enum';
import { FormType } from '@shared/enums/form-type.enum';
import { HealthyWeightRank } from '@shared/enums/healthy-weight-rank.enum';
import { PractitionerType } from '@shared/enums/practitioner.enum';
import { Practitioner } from '@shared/models/practitioner.model';
import { TreatmentCenterDictionary } from '@shared/models/treatment-center.model';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { WsHelperService } from '@shared/services/ws-helper.service';
import {
  anesthesiaMediaAcceptedTypes,
  imageConfigs,
  interventionTypeControlNames,
  SurgicalInterventionForm
} from '@src/app/features/surgical/components/surgical-intervention-form/surgical-intervention-form.model';
import { AsyncButtonClickAction } from '@utils/button.utils';
import { getNextDay } from '@utils/date.utils';
import { FormMode, yesNoAnswers } from '@utils/form.utils';
import { intervention, surgicalRoute } from '@utils/routing.utils';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

@Component({
  selector: 'stx-surgical-intervention-form',
  templateUrl: './surgical-intervention-form.component.html'
})
export class SurgicalInterventionFormComponent extends BaseTreatmentFormComponent<SurgicalIntervention> implements OnInit {
  readonly acceptedMediaTypes = anesthesiaMediaAcceptedTypes;
  readonly yesNo = yesNoAnswers;
  readonly complicationTypes = [
    { label: 'surgical_intervention.complication_breathing_problems', name: 'complicationBreathingProblems' },
    { label: 'surgical_intervention.complication_dehiscence', name: 'complicationDehiscence' },
    { label: 'surgical_intervention.complication_fistula', name: 'complicationFistula' },
    { label: 'surgical_intervention.complication_back_to_or', name: 'complicationBackToOr' }
  ];
  readonly imageConfigs = imageConfigs;
  readonly form: SurgicalInterventionForm;
  healthyWeightRank: HealthyWeightRank | 'loading' | 'loading-failed' | null;
  surgeonList: Practitioner[] = [];
  anesthesiologistList: Practitioner[] = [];
  anesthMethods: DictionaryTranslation[] = [];
  nextDayAfterDob: moment.Moment;
  public readonly tcList$: BehaviorSubject<TreatmentCenterDictionary[]>;
  private idToEdit?: TreatmentId;

  constructor(
    private cd: ChangeDetectorRef,
    router: Router,
    patientService: PatientService,
    private surgicalService: SurgicalService,
    spinnerService: SpinnerService,
    private treatmentCenterService: TreatmentCenterService,
    globalErrorHandlerService: GlobalErrorHandlerService,
    activatedRoute: ActivatedRoute,
    snackBarService: SnackBarService,
    elementRef: ElementRef,
    zone: NgZone,
    wsHelper: WsHelperService
  ) {
    super(
      elementRef,
      zone,
      snackBarService,
      spinnerService,
      router,
      activatedRoute,
      cd,
      globalErrorHandlerService,
      wsHelper,
      patientService
    );
    this.formType = FormType.SURGICAL_INTERVENTION;
    this.treatmentType = TreatmentType.SURGERY;
    this.tcList$ = new BehaviorSubject<TreatmentCenterDictionary[]>([]);
    this.form = new SurgicalInterventionForm(this.tcList$);
    this.formGroup = this.form.formGroup;
    this.configureForm(this.formGroup, this.form.saveValidationConfig);
  }

  @Input()
  set surgicalIntervention(data: SurgicalIntervention) {
    if (data) {
      this.setTreatmentData(data);
    }
  }

  ngOnInit() {
    this.handleWsCall(this.surgicalService.getSurgeryStaticDictionaries(), dictionary => {
      this.staticDictionaries = dictionary;
      this.anesthMethods = this.staticDictionaries.get('anesthesiaMethod');
      this.cd.detectChanges();
    });
    this.nextDayAfterDob = getNextDay(this.patient!.dateOfBirth);
    this.subSink.sink = this.wsHelper.pipeToSubject(
      this.patientService.getPatientTreatmentCenters(this.patient!.id, TreatmentType.SURGERY).pipe(
        tap(treatmentCenters => {
          this.cd.markForCheck();
          this.tcList = treatmentCenters;
        })
      ),
      this.tcList$
    );
    this.subSink.sink = this.form.treatmentCenter$.subscribe(treatmentCenter => {
      if (!treatmentCenter) {
        return;
      }
      this.loadSurgeonsBySelectedTcId(treatmentCenter.id);
      this.loadAnesthesiologistsBySelectedTcId(treatmentCenter.id);
    });
    this.subSink.sink = combineLatest([this.form.treatmentCenter$, this.form.operationDate$]).subscribe(
      ([treatmentCenter, operationDate]) => {
        if (!treatmentCenter) {
          return;
        }

        if (treatmentCenter?.allowVelo) {
          this.handleWsCall(this.patientService.isPatientEligibleForVpd(this.patient.id, operationDate), result => {
            this.form.displayVelopharyngealDisfunction = result;
          });
        } else {
          this.form.displayVelopharyngealDisfunction = false;
        }
      }
    );
  }

  maxOfDatesOrDayAfterDob(dates?: moment.Moment[]): moment.Moment {
    const filteredDates = (dates ?? []).filter(x => x != null);
    filteredDates.push(this.nextDayAfterDob);
    return moment.max(filteredDates.map(date => moment(date)));
  }

  minOfDatesOrToday(dates?: moment.Moment[]): moment.Moment {
    const filteredDates = (dates ?? []).filter(x => x != null);
    filteredDates.push(this.today);
    return moment.min(filteredDates.map(date => moment(date)));
  }

  get shouldDisplaySurgicalOperationError(): boolean {
    const isAnyControlTouched: boolean = interventionTypeControlNames.some(
      (controlName: string) => !!this.formGroup.get(controlName).touched
    );

    return isAnyControlTouched && this.formGroup.hasError('surgicalOperationTypes');
  }

  protected callDelete(id: number): Observable<void> {
    throw new Error('illegal delete call');
  }

  protected callSave(data: SurgicalIntervention): Observable<SurgicalIntervention> {
    return this.surgicalService.saveSurgery(data);
  }

  protected callSubmit(data: SurgicalIntervention): Observable<SurgicalIntervention> {
    return this.surgicalService.submitSurgery(data);
  }

  protected callUnlock(id: number): Observable<void> {
    throw new Error('illegal unlock call');
  }

  onSubmitWithSaQOverrideButtonClicked(): AsyncButtonClickAction {
    return () => {
      return this.beforeValidationAsync().pipe(
        mergeMap(beforeValidationResult => {
          if (!beforeValidationResult.proceed) {
            return this.onSubmitOrSaveValidationFails();
          }
          this.beforeValidationSync();

          if (!this.refreshValidityStateForSubmit()) {
            return this.onSubmitOrSaveValidationFails();
          }

          return this.afterSubmitValidationAsync().pipe(
            mergeMap(afterValidationResult => {
              if (afterValidationResult.proceed) {
                return this.onSubmitWithSaQOverrideValidationSuccess();
              } else {
                return this.onSubmitOrSaveValidationFails();
              }
            })
          );
        })
      );
    };
  }

  private onSubmitWithSaQOverrideValidationSuccess(): Observable<SurgicalIntervention> {
    const dataToBeSaved = this.getTreatmentDataToSave();
    return this.wsHelper.callWithSpinner(
      this.callSubmitWithSaQOverride(dataToBeSaved).pipe(
        tap(submittedData => {
          this.setTreatmentData(submittedData);
          this.router.navigate([this.getViewRoute(submittedData.id)], {
            queryParams: { patientId: this.getPatientId() }
          });
          this.dataSaved.emit();
          this.onTreatmentSubmitted();
          this.changeDetector.markForCheck();
        })
      )
    );
  }

  private callSubmitWithSaQOverride(data: SurgicalIntervention) {
    return this.surgicalService.submitSurgeryWithSaQProtocolsOverride(data);
  }

  protected getTreatmentDataToSave(): SurgicalIntervention {
    return {
      id: this.idToEdit,
      patientId: this.patient.id,
      ...this.form.data
    };
  }

  protected getEditRoute(treatmentId: number): string {
    return `${surgicalRoute}/${intervention}/edit/${treatmentId}`;
  }

  protected getPatientId(): PatientId {
    return this.patient.id;
  }

  protected getTreatmentId(): TreatmentId {
    return this.idToEdit;
  }

  protected getViewRoute(treatmentId: TreatmentId): string {
    return `${surgicalRoute}/${intervention}/${treatmentId}`;
  }

  protected setTreatmentData(data: SurgicalIntervention): void {
    this.idToEdit = data.id;
    this.form.data = data;
  }

  /* TODO#36489 - this is temporary solution as we do not have a way to handle file deletion on backend.
 TODO #36489 The task will be fully completed in scope of ticket 36489. Right now deletion is only available for
 TODO #36489 new forms - for editing we do not have remove icon.
  */
  get shouldDisplayRemoveFileIcon(): boolean {
    return this.formMode === FormMode.NEW;
  }

  private loadSurgeonsBySelectedTcId(selectedTreatmentCenterId: number): void {
    this.handleWsCall(
      this.treatmentCenterService.getPractitionersByTreatmentCenterAndType(selectedTreatmentCenterId, PractitionerType.SURGEON),
      surgeons => {
        this.surgeonList = surgeons;
      }
    );
  }

  private loadAnesthesiologistsBySelectedTcId(selectedTreatmentCenterId: number): void {
    this.handleWsCall(
      this.treatmentCenterService.getPractitionersByTreatmentCenterAndType(selectedTreatmentCenterId, PractitionerType.ANESTHESIOLOGIST),
      anesthesiologists => {
        this.anesthesiologistList = anesthesiologists;
      }
    );
  }
}
