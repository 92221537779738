import { Component } from '@angular/core';
import { FormModule } from '@shared/components/form/form.module';
import { MatIcon } from '@angular/material/icon';
import { ModalModule } from '@shared/components/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'stx-non-global-password-expiry-modal',
  standalone: true,
  imports: [FormModule, MatIcon, ModalModule, TranslateModule],
  templateUrl: './non-global-password-expiry-modal.component.html',
  styleUrl: './non-global-password-expiry-modal.component.scss'
})
export class NonGlobalPasswordExpiryModalComponent {}
