<stx-search-filter-container [config]="{ isCsvAvailable: true }">
  <form [formGroup]="searchFormGroup">
    <stx-search-filter-columns (searchButtonClicked)="initiateSearch()">
      <ng-container *stxSearchFilterColumn>
        <div class="form-row">
          <div class="form-element-group-column">
            <mat-checkbox data-testid="checkbox-partner-search-active" formControlName="active"> {{ 'active' | translate }}</mat-checkbox>
            <mat-checkbox data-testid="checkbox-partner-search-inactive" formControlName="inactive">
              {{ 'inactive' | translate }}</mat-checkbox
            >
          </div>
        </div>
        <hr class="form-spaced-subsection-separator" />
        <div class="form-row">
          <div class="form-element-group-column">
            <mat-checkbox data-testid="checkbox-partner-search-tc-outreach" formControlName="outreachCenter">
              {{ 'treatment_center.outreach' | translate }}</mat-checkbox
            >
            <mat-checkbox data-testid="checkbox-partner-search-tc-display" formControlName="webIgnore">
              {{ 'treatment_center.dont_display' | translate }}</mat-checkbox
            >
          </div>
        </div>
      </ng-container>
      <ng-container *stxSearchFilterColumn>
        <div class="form-row">
          <div class="form-element-group-column">
            <mat-checkbox data-testid="checkbox-treatment-surgery" formControlName="allowSurgery">
              {{ 'treatment.surgery' | translate }}</mat-checkbox
            >
            <mat-checkbox data-testid="checkbox-treatment-vpd-surgery" formControlName="allowVelo">
              {{ 'treatment.vpd_surgery' | translate }}</mat-checkbox
            >
            <mat-checkbox data-testid="checkbox-treatment-orthognathic" formControlName="allowOrthognathic">
              {{ 'treatment.orthognathic_treatment' | translate }}</mat-checkbox
            >
            <mat-checkbox data-testid="checkbox-treatment-borescope" formControlName="allowBorescope">
              {{ 'treatment.borescope' | translate }}</mat-checkbox
            >
            <mat-checkbox data-testid="checkbox-treatment-orthodontic" formControlName="allowOrtho">
              {{ 'treatment.orthodontics' | translate }}</mat-checkbox
            >
            <mat-checkbox data-testid="checkbox-treatment-speech" formControlName="allowSpeech">
              {{ 'treatment.speech' | translate }}</mat-checkbox
            >
            <mat-checkbox data-testid="checkbox-treatment-nutrition" formControlName="allowNutrition">
              {{ 'treatment.nutrition' | translate }}</mat-checkbox
            >
            <mat-checkbox data-testid="checkbox-treatment-dental" formControlName="allowDental">{{
              'treatment.dental' | translate
            }}</mat-checkbox>
            <mat-checkbox data-testid="checkbox-treatment-ent" formControlName="allowEnt">{{ 'treatment.ent' | translate }}</mat-checkbox>
            <mat-checkbox data-testid="checkbox-treatment-psychological" formControlName="allowPsychosocial">{{
              'treatment.psychosocial' | translate
            }}</mat-checkbox>
          </div>
        </div>
        <hr class="form-spaced-subsection-separator" />
        <div class="form-row">
          <div class="form-element-group-column">
            <mat-checkbox data-testid="checkbox-tc-cost-share" formControlName="costSharePrimaryNotCovered">
              {{ 'treatment_center.china_cost_sharing_exemption' | translate }}</mat-checkbox
            >
          </div>
        </div>
      </ng-container>
      <ng-container *stxSearchFilterColumn>
        <div *stxPermitted="PermissionEnum.GET_ORG_TREE_FILTERS">
          <div class="form-row">
            <stx-org-tree-filter [orgTreeFilters]="orgTreeFiltersData" [formGroupReference]="searchFormGroup"></stx-org-tree-filter>
          </div>
          <hr class="form-spaced-subsection-separator" />
          <div class="form-row">
            <stx-geography-filter [orgTreeFilters]="orgTreeFiltersData" [formGroupReference]="searchFormGroup"></stx-geography-filter>
          </div>
        </div>
      </ng-container>
    </stx-search-filter-columns>
  </form>
</stx-search-filter-container>
