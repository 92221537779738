<stx-search-filter-container [config]="userSearchFilterContainerConfig">
  <form [formGroup]="searchFormGroup">
    <stx-search-filter-columns (searchButtonClicked)="initiateSearch()">
      <ng-container *stxSearchFilterColumn>
        <div class="form-row">
          <stx-user-role-filter></stx-user-role-filter>
        </div>
        <hr class="form-spaced-subsection-separator" />
        <div class="form-row">
          <div class="form-element-group-column">
            <mat-checkbox data-testid="checkbox-user-search-active" formControlName="active"> {{ 'active' | translate }}</mat-checkbox>
            <mat-checkbox data-testid="checkbox-user-search-inactive" formControlName="inactive">
              {{ 'inactive' | translate }}</mat-checkbox
            >
          </div>
        </div>
        <hr class="form-spaced-subsection-separator" />
        <div class="form-row">
          <div class="form-element-group-column">
            <mat-checkbox data-testid="checkbox-user-search-logged-in-today" formControlName="loggedInToday">
              {{ 'user.logged_in_today' | translate }}</mat-checkbox
            >
          </div>
        </div>
      </ng-container>
      <ng-container *stxSearchFilterColumn>
        <div class="form-row">
          <div class="form-element-group-column">
            <mat-checkbox data-testid="checkbox-user-search-domain" formControlName="domainAccess">
              {{ 'user.domain_access' | translate }}</mat-checkbox
            >
          </div>
        </div>
        <hr class="form-spaced-subsection-separator" />
        <div class="form-row">
          <stx-org-tree-filter
            [showPartial]="geoTreePartialUserFiltersType"
            [orgTreeFilters]="orgTreeFiltersData"
            [formGroupReference]="searchFormGroup"
          ></stx-org-tree-filter>
        </div>
      </ng-container>
      <ng-container *stxSearchFilterColumn>
        <div class="form-row">
          <stx-geography-filter
            [orgTreeFilters]="orgTreeFiltersData"
            [formGroupReference]="searchFormGroup"
            [showPartial]="GeoEnum.COUNTRY"
          ></stx-geography-filter>
        </div>
      </ng-container>
    </stx-search-filter-columns>
  </form>
</stx-search-filter-container>
