import { PermissionEnum } from '@core/permissions/permission.enum';
import {
  educationAndResearchFullRoute,
  medicalFormsRoute,
  nutritionResourcesFullRoute,
  orthoQaMdRoute,
  orthoQaPdRoute,
  orthoQaPsioRoute,
  patientsRoute,
  protocolsRoute,
  resourcesForFamiliesRoute,
  resourcesRoute,
  reviewRoute,
  speechQaSaRoute,
  speechResourcesFullRoute,
  userGuideRoute
} from '@utils/routing.utils';
import { NavigationSubTabEnum } from './model/nav-item-enum.enum';
import { NavItem } from './model/nav-item.model';

export const resourcesNavigationSubItems: NavItem[] = [
  {
    id: NavigationSubTabEnum.RESOURCES_PROTOCOLS_ADVISORIES_GUIDELINES,
    text: 'menu.resources.protocols_advisories_guidelines',
    href: `${resourcesRoute}/${protocolsRoute}`
  },
  {
    id: NavigationSubTabEnum.RESOURCES_MEDICAL_FORMS,
    text: 'menu.resources.medical_forms',
    href: `${resourcesRoute}/${medicalFormsRoute}`
  },
  {
    id: NavigationSubTabEnum.RESOURCES_EDUCATION_AND_RESEARCH,
    text: 'menu.resources.education_research',
    href: educationAndResearchFullRoute
  },
  {
    id: NavigationSubTabEnum.RESOURCES_SPEECH,
    text: 'menu.resources.speech',
    href: speechResourcesFullRoute
  },
  {
    id: NavigationSubTabEnum.RESOURCES_NUTRITION,
    text: 'menu.resources.nutrition',
    href: nutritionResourcesFullRoute
  },
  {
    id: NavigationSubTabEnum.RESOURCES_FOR_FAMILIES,
    text: 'menu.resources.resources_for_families',
    href: `${resourcesRoute}/${resourcesForFamiliesRoute}`
  },
  { id: NavigationSubTabEnum.RESOURCES_USER_GUIDE, text: 'menu.resources.user_guide', href: `${resourcesRoute}/${userGuideRoute}` }
];

export const navItems: NavItem[] = [
  {
    text: 'menu.patients',
    href: patientsRoute,
    permission: PermissionEnum.GET_PATIENTS
  },
  {
    text: 'menu.patients',
    href: `${reviewRoute}/surgical`,
    permission: PermissionEnum.GET_SURGERY_FOR_REVIEW
  },
  {
    text: 'menu.partners',
    href: '/partners',
    permission: PermissionEnum.GET_PARTNERS_RECORDS
  },
  {
    text: 'menu.practitioners',
    href: '/practitioners',
    permission: PermissionEnum.GET_PRACTITIONERS
  },
  {
    text: 'menu.psio',
    href: `${orthoQaPsioRoute}`,
    permission: PermissionEnum.VIEW_DASHBOARD_REVIEW_ORTHO
  },
  {
    text: 'menu.md',
    href: `${orthoQaMdRoute}`,
    permission: PermissionEnum.VIEW_DASHBOARD_REVIEW_ORTHO
  },
  {
    text: 'menu.pd',
    href: `${orthoQaPdRoute}`,
    permission: PermissionEnum.VIEW_DASHBOARD_REVIEW_ORTHO
  },
  {
    text: 'menu.sa',
    href: `${speechQaSaRoute}`,
    permission: PermissionEnum.VIEW_DASHBOARD_REVIEW_SPEECH
  },
  {
    text: 'menu.reports',
    href: '/reports',
    permission: PermissionEnum.GET_REPORTS
  },
  {
    text: 'menu.users',
    href: '/users',
    permission: PermissionEnum.GET_USERS
  },
  {
    text: 'tools',
    href: '/tools',
    permission: PermissionEnum.VIEW_TOOLS
  },
  {
    text: 'menu.resources',
    href: `${resourcesRoute}/${medicalFormsRoute}`,
    permission: PermissionEnum.GET_RESOURCES,
    subItems: resourcesNavigationSubItems
  }
];
